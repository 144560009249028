import {find} from 'lodash-es';

export function useGoogleAds() {

    const inventory = {
        leaderboard_a: {
            slot: '/45099537/Sigmalive/sigmalive_leaderboard_a',
            size: [
                [245, 90],
                [468, 60],
                [728, 90],
            ],
            id: 'div-gpt-ad-1716802870785-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [245, 90],
                },
                {
                    viewport_size: [500, 200],
                    sized_ad: [468, 60],
                },
                {
                    viewport_size: [760, 200],
                    sized_ad: [728, 90],
                },
            ],
        },

        small_leaderboard_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_small_leaderboard_mobiles',
            size: [
                [245, 90],
                [468, 60],
            ],
            id: 'div-gpt-ad-1716809912996-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [245, 90],
                },
                {
                    viewport_size: [500, 200],
                    sized_ad: [468, 60],
                },
                {
                    viewport_size: [978, 200],
                    sized_ad: [],
                },
            ],
        },
        small_leaderboard_desktop: {
            slot: '/45099537/Sigmalive/sigmalive_small_leaderboard_desktop',
            size: [245, 90],
            id: 'div-gpt-ad-1716809682622-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [978, 200],
                    sized_ad: [245, 90],
                },
            ],
        },

        leaderboard_b_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_leaderboard_b_mobiles',
            id: 'div-gpt-ad-1725353020815-0',
            size: [
                [245, 90],
                [468, 60],
            ],
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [245, 90],
                },
                {
                    viewport_size: [500, 200],
                    sized_ad: [468, 60],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [],
                },
            ],
        },
        leaderboard_b: {
            slot: '/45099537/Sigmalive/sigmalive_leaderboard_b',
            size: [728, 90],
            id: 'div-gpt-ad-1725352817981-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [728, 90],
                },
            ],
        },
        small_leaderboard_b_mobiles245_90: {
            slot: '/45099537/Sigmalive/sigmalive_245x90_small_leaderboard_b_mobiles',
            id: 'div-gpt-ad-1725354233212-0',
            size: [245, 90],
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [245, 90],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [],
                },
            ],
        },
        small_leaderboard_b: {
            slot: '/45099537/Sigmalive/sigmalive_245x90_small_leaderboard_B',
            id: 'div-gpt-ad-1670251688185-0',
            size: [245, 90],
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [1280, 200],
                    sized_ad: [245, 90],
                },
            ],
        },

        sidebar_small_leaderboard_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_small_leaderboard_mobiles',
            size: [300, 100],
            id: 'div-gpt-ad-1725355046939-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [245, 90],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [],
                },
            ],
        },

        sidebar_small_leaderboard_desktop: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_small_leaderboard_desktop',
            size: [300, 100],
            id: 'div-gpt-ad-1716811792098-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [1280, 200],
                    sized_ad: [300, 100],
                },
            ],
        },

        sidebar_a_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_a_mobiles',
            size: [
                [300, 250],
                [728, 90],
            ],
            id: 'div-gpt-ad-1716815588946-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [300, 250],
                },
                {
                    viewport_size: [760, 200],
                    sized_ad: [728, 90],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [],
                },
            ],
        },

        sidebar_a_desktop: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_a_desktop',
            size: [
                [200, 200],
                [300, 250],
            ],
            id: 'div-gpt-ad-1716815446867-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [200, 200],
                },
                {
                    viewport_size: [1280, 200],
                    sized_ad: [[300, 250]],
                },
            ],
        },

        sidebar_b_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_b_mobiles',
            size: [
                [300, 250],
                [728, 90],
            ],
            id: 'div-gpt-ad-1716883945108-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [300, 250],
                },
                {
                    viewport_size: [760, 200],
                    sized_ad: [728, 90],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [],
                },
            ],
        },

        sidebar_b_desktop: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_b_desktop',
            size: [
                [200, 200],
                [300, 250],
            ],
            id: 'div-gpt-ad-1716883487726-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [200, 200],
                },
                {
                    viewport_size: [1280, 200],
                    sized_ad: [[300, 250]],
                },
            ],
        },

        sidebar_c_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_c_mobiles',
            size: [
                [300, 250],
                [728, 90],
            ],
            id: 'div-gpt-ad-1716885955580-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [300, 250],
                },
                {
                    viewport_size: [760, 200],
                    sized_ad: [728, 90],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [],
                },
            ],
        },

        sidebar_c_desktop: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_c_desktop',
            size: [
                [200, 200],
                [300, 250],
            ],
            id: 'div-gpt-ad-1716885512647-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [200, 200],
                },
                {
                    viewport_size: [1280, 200],
                    sized_ad: [300, 250],
                },
            ],
        },

        sidebar_d_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_d_mobiles',
            size: [
                [300, 250],
                [728, 90],
            ],
            id: 'div-gpt-ad-1716897074773-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [300, 250],
                },
                {
                    viewport_size: [760, 200],
                    sized_ad: [728, 90],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [],
                },
            ],
        },

        sidebar_d_desktop: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_d_desktop',
            size: [
                [200, 200],
                [300, 250],
            ],
            id: 'div-gpt-ad-1716896932359-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [200, 200],
                },
                {
                    viewport_size: [1280, 200],
                    sized_ad: [300, 250],
                },
            ],
        },

        sidebar_e_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_e_mobiles',
            size: [
                [300, 250],
                [728, 90],
            ],
            id: 'div-gpt-ad-1716897931243-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [300, 250],
                },
                {
                    viewport_size: [760, 200],
                    sized_ad: [728, 90],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [],
                },
            ],
        },

        sidebar_e_desktop: {
            slot: '/45099537/Sigmalive/sigmalive_sidebar_e_desktop',
            size: [
                [200, 200],
                [300, 250],
            ],
            id: 'div-gpt-ad-1716897692042-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [200, 200],
                },
                {
                    viewport_size: [1280, 200],
                    sized_ad: [300, 250],
                },
            ],
        },

        leaderboard_c_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_leaderboard_c_mobiles',
            id: 'div-gpt-ad-1725362567952-0',
            size: [
                [245, 90],
                [468, 60],
            ],
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [245, 90],
                },
                {
                    viewport_size: [500, 200],
                    sized_ad: [468, 60],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [],
                },
            ],
        },

        leaderboard_c: {
            slot: '/45099537/Sigmalive/sigmalive_leaderboard_c',
            size: [728, 90],
            id: 'div-gpt-ad-1716887854442-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [1024, 200],
                    sized_ad: [728, 90],
                },
            ],
        },

        in_article_a: {
            slot: '/45099537/Sigmalive/sigmalive_in_article_a',
            size: [300, 250],
            id: 'div-gpt-ad-1716976523611-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [300, 250],
                },
            ],
        },
        in_texter_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_in_texter_mobiles',
            size: [300, 250],
            id: 'div-gpt-ad-1717056280823-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [320, 200],
                    sized_ad: [[300, 250]],
                },
                {
                    viewport_size: [776, 200],
                    sized_ad: [],
                },
            ],
        },

        in_texter_desktop: {
            slot: '/45099537/Sigmalive/sigmalive_in_texter_desktop',
            size: [728, 90],
            id: 'div-gpt-ad-1717055750219-0',
            size_mapping: [
                {
                    viewport_size: [0, 0],
                    sized_ad: [],
                },
                {
                    viewport_size: [776, 200],
                    sized_ad: [[728, 90]],
                },
            ],
        },

        takeover_desktop: {
            slot: '/45099537/Sigmalive/sigmalive_takeover_desktop_700x470',
            id: 'div-gpt-ad-1725343889985-0',
            size: [700, 470],
        },
        takeover_mobiles: {
            slot: '/45099537/Sigmalive/sigmalive_takeover_mobiles_300x250',
            id: 'div-gpt-ad-1725344027667-0',
            size: [300, 250],
        },
        catfish: {
            slot: '/45099537/Sigmalive/sigmalive_catfish_300x100',
            id: 'div-gpt-ad-1725344429808-0',
            size: [300, 100],
        },
        skin_left: {
            slot: '/45099537/Sigmalive/sigmalive_skin_left_120x600',
            id: 'div-gpt-ad-1725344194496-0',
            size: [120, 600],
        },
        skin_right: {
            slot: '/45099537/Sigmalive/sigmalive_skin_right_120x600',
            id: 'div-gpt-ad-1725344296406-0',
            size: [120, 600],
        },
    };
    const checkSlotIsDefined = (unit) => {

        if (typeof googletag !== 'undefined' && typeof googletag.pubads === 'function') {
            const slots = googletag.pubads().getSlots();

            return find(slots, function (slot) {
                return slot.getSlotElementId() === unit.id;
            });
        }

        return false;
    };

    const defineSlots = (unit, collapseDiv = false) => {

        let slotObject = null;
        if (typeof googletag !== 'undefined') {
            googletag = googletag || {cmd: []};

            googletag.cmd.push(function () {

                let sizeMapping = null;

                if (unit.size_mapping) {
                    const googleTagSizeMapping = googletag.sizeMapping();

                    unit.size_mapping.forEach(sizeMap => {
                        googleTagSizeMapping.addSize(sizeMap.viewport_size, sizeMap.sized_ad);
                    });
                    sizeMapping = googleTagSizeMapping.build();
                }


                slotObject = googletag.defineSlot(unit.slot, unit.size, unit.id);

                if (sizeMapping) {
                    slotObject.defineSizeMapping(sizeMapping);
                }

                slotObject.addService(googletag.pubads());

                if (collapseDiv) {
                    slotObject.setCollapseEmptyDiv(true);
                }

                googletag.pubads().enableSingleRequest();
                googletag.pubads().collapseEmptyDivs();
                googletag.enableServices();
            });
        }


        return slotObject;
    };

    const displayAds = unit => {
        if (typeof googletag !== 'undefined') {
            googletag.cmd.push(function () {
                googletag.display(unit.id);
            });
        }


    };

    const showAd = (unit, collapseDiv = false) => {

        const existingSlot = checkSlotIsDefined(unit);

        if (existingSlot) {
            googletag.pubads().refresh([existingSlot]);
            return;
        }
        // create GPT ads slots again
        defineSlots(unit, collapseDiv);

        // display GPT ads
        displayAds(unit);
    };

    const destroySlot = slotObject => {
        if (typeof googletag !== 'undefined') {
            googletag.destroySlots([slotObject]);
        }


    };

    const setCategoryTargeting = categories => {
        if (typeof googletag !== 'undefined' && typeof googletag.pubads === 'function') {
            googletag.pubads().setTargeting('category', categories);
        }
    };

    const setPostTargeting = postId => {
        if (typeof googletag !== 'undefined' && typeof googletag.pubads === 'function') {
            googletag.pubads().setTargeting('nid', [`${postId}`]);
        }
    };


    return {
        inventory,
        showAd,
        destroySlot,
        displayAds,
        checkSlotIsDefined,
        setCategoryTargeting,
        setPostTargeting,
    };

}